import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <p>fuck sinpei</p>
    </div>
  );
}

export default App;
